import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import {
  ACTIVE,
  DEPLETING,
  MEAL_KIT_AND_RTH_S_AND_R_ONLY,
  MEAL_KIT_ONLY,
  NEEDS_APPROVAL,
  R_AND_D_READY,
  RTH_AND_MEAL_KIT,
  RTH_ONLY,
  SCALING_WIP,
  SHORTS_AND_REPLACEMENTS,
} from 'lib/constants';
import { MEF_URL } from 'lib/constants/mealEditForm';
import clsx from 'clsx';

import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { GET } from 'lib/api';

const IngredientsSearch = ({
  classes,
  compoundIngredientId,
  autocompleteOnChange,
  ingredientSelected,
  includeShortsAndReplacements,
  isReplacement,
  name,
  label,
  rthProductType,
  disabled,
}) => {
  const [ingredientResults, setIngredientResults] = useState([]);
  const [checked, setChecked] = useState(includeShortsAndReplacements);

  const tableHeader = {
    id: 'ID',
    name: 'Name',
    brandName: 'Brand Name',
    displayName: 'Display Name',
    category: 'Category',
    state: 'State',
    approved: 'Approved',
  };

  const stateParams = () => {
    const states = [ACTIVE, R_AND_D_READY, SCALING_WIP];
    if (checked) {
      states.push(SHORTS_AND_REPLACEMENTS);
      states.push(DEPLETING);
    }
    // basic
    if (!compoundIngredientId && rthProductType) {
      states.push(NEEDS_APPROVAL);
    }
    return states;
  };

  const eligibilitiesParams = () => {
    const eligibilities = [RTH_AND_MEAL_KIT];
    if (compoundIngredientId) {
      if (checked) {
        eligibilities.push(MEAL_KIT_AND_RTH_S_AND_R_ONLY);
      } else {
        eligibilities.push(RTH_ONLY);
      }
    } else {
      if (rthProductType) {
        if (isReplacement) {
          eligibilities.push(MEAL_KIT_AND_RTH_S_AND_R_ONLY);
        }
        eligibilities.push(RTH_ONLY);
      }
      eligibilities.push(MEAL_KIT_ONLY, MEAL_KIT_AND_RTH_S_AND_R_ONLY);
    }
    return eligibilities;
  };

  const handleIngredientSearch = async (searchTerm) => {
    if (searchTerm) {
      setIngredientResults([]);

      await GET(`${MEF_URL}/ingredients_search`, {
        params: {
          term: searchTerm,
          with_states: stateParams(),
          with_eligibilities: eligibilitiesParams(),
          camelcase: true,
        },
      })
        .then((response) => {
          const results = response.data;
          if (compoundIngredientId) {
            setIngredientResults([tableHeader, ...results.baseIngredients]);
          }
          const filteredResults = results.baseIngredients.filter(
            (result) => result.id !== compoundIngredientId
          );
          setIngredientResults([tableHeader, ...filteredResults]);
        })
        .catch(() => setIngredientResults([]));
    }
  };

  const debouncedHandleIngredientSearch = debounce(handleIngredientSearch, 400);

  const setSearch = (event) => {
    debouncedHandleIngredientSearch(event.target.value);
    return debouncedHandleIngredientSearch.cancel;
  };

  const renderSearchTextField = (params) => {
    return (
      <TextField
        data-testid="ingredient-search-input"
        variant="outlined"
        {...params}
        onChange={setSearch}
        label={label}
        size="small"
        InputProps={{ ...params.InputProps, style: { fontSize: 14 } }}
        InputLabelProps={{ style: { fontSize: 13 } }}
        disabled={disabled}
      />
    );
  };

  const optionTextStyle = { fontSize: 13, overflowWrap: 'break-word' };

  return (
    <div
      className={clsx(
        classes.ingredientTextSearchBox,
        !!includeShortsAndReplacements && classes.mb_8
      )}
    >
      <Autocomplete
        freeSolo
        name={name}
        data-testid="ingredient-search-results"
        options={ingredientResults}
        getOptionDisabled={(option) => option.id === 'ID' && option.name === 'Name'}
        getOptionLabel={(option) => option.name || ''}
        onChange={autocompleteOnChange}
        value={ingredientSelected}
        filterOptions={(x) => x}
        renderOption={(props, option) => (
          <li style={{ minWidth: '850px' }} {...props} key={option.id}>
            <Grid container style={{ minWidth: '850px' }} spacing={1}>
              <Grid item xs={1}>
                <Typography style={optionTextStyle}>{option.id}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography style={optionTextStyle}>{option.name}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography style={optionTextStyle}>{option.brandName}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography style={optionTextStyle}>{option.displayName}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={optionTextStyle}>{option.category}</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography style={optionTextStyle}>{option.state}</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography style={optionTextStyle}>{option.approved.toString()}</Typography>
              </Grid>
            </Grid>
          </li>
        )}
        renderInput={(params) => renderSearchTextField(params)}
      />
      {/* The checkbox will only be rendered if the includeShortsAndReplacements prop is false.
      The checkbox will not be shown for the replacement ingredient card.
      These will include shorts and replacements by default. */}
      {!includeShortsAndReplacements && (
        <>
          <Checkbox
            name="searchWithShortsAndReplacements"
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
          />
          <span className={classes.label}>Include shorts and replacements</span>
        </>
      )}
    </div>
  );
};

IngredientsSearch.propTypes = {
  classes: PropTypes.object,
  compoundIngredientId: PropTypes.number,
  autocompleteOnChange: PropTypes.func.isRequired,
  ingredientSelected: PropTypes.object,
  includeShortsAndReplacements: PropTypes.bool,
  isReplacement: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  rthProductType: PropTypes.bool,
  disabled: PropTypes.bool,
};

IngredientsSearch.defaultProps = {
  classes: null,
  compoundIngredientId: null,
  ingredientSelected: {},
  includeShortsAndReplacements: false,
  isReplacement: false,
  rthProductType: false,
  disabled: false,
};

export default IngredientsSearch;
