import { MEF_URL } from 'lib/constants/mealEditForm';
import { GET } from 'lib/api';

export const fetchMealAllergenValidation = (params) => {
  const { mealId, replacementIngredientId } = params;

  return GET(`${MEF_URL}/validate_meal_allergens`, {
    params: {
      meal_allergen_params: { meal_id: mealId, replacement_ingredient_id: replacementIngredientId },
    },
  });
};
