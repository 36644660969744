import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { format, fromUnixTime } from 'date-fns';

import { AppBar, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { ArrowBackIosRounded, ArrowForwardIosRounded } from '@mui/icons-material';

import { changeQuarter } from 'redux/menugrid/quarter/actions';

const Header = ({ classes }) => {
  const quarterStart = useSelector((state) => state.menugrid.quarter.start);
  const quarterEnd = useSelector((state) => state.menugrid.quarter.end);

  const setTabs = () => {
    return { '/menugrid/core': 'Core', '/menugrid/family': 'Family', '/menugrid/tempo': 'Tempo' };
  };

  const removeNumbersFromPathname = (pathname) => {
    const secondSlashIndex = pathname.indexOf('/', 1);
    // If the second slash exists, remove everything after it
    if (secondSlashIndex !== -1) return pathname.substring(0, secondSlashIndex);
    // If there is no second slash, return the original pathname
    return pathname;
  };

  const dispatch = useDispatch();
  const location = useLocation();

  const cleanedPathname = removeNumbersFromPathname(location.pathname);

  const tabs = setTabs();
  const [tab, setTab] = useState(tabs[cleanedPathname] || tabs['/menugrid/core']);

  const handleTabChange = (_event, newValue) => {
    setTab(newValue);
  };

  const handleBackArrowClick = () => {
    dispatch(changeQuarter(quarterStart, 'previous'));
  };

  const handleForwardArrowClick = () => {
    dispatch(changeQuarter(quarterStart, 'next'));
  };

  const renderArrows = () => {
    return (
      <>
        <IconButton onClick={handleBackArrowClick} size="large">
          <ArrowBackIosRounded className={classes.arrow} />
        </IconButton>
        <IconButton onClick={handleForwardArrowClick} size="large">
          <ArrowForwardIosRounded className={classes.arrow} />
        </IconButton>
      </>
    );
  };

  const renderTabs = () => {
    return (
      <>
        <Tabs
          className={classes.tabs}
          value={tab}
          onChange={handleTabChange}
          classes={{ indicator: classes.indicator }}
        >
          {Object.entries(tabs).map(([key, value]) => (
            <Tab
              className={classes.tab}
              label={value}
              value={value}
              to={key}
              key={key}
              classes={{ selected: classes.selectedTab }}
              component={Link}
            />
          ))}
        </Tabs>
      </>
    );
  };

  return (
    <AppBar position="static" className={classes.root} color="default">
      <Typography className={classes.title}>Menu Grid</Typography>
      <Typography className={classes.quarterDate} align="center">
        {`${format(fromUnixTime(quarterStart), 'PP')} - ${format(fromUnixTime(quarterEnd), 'PP')}`}
      </Typography>
      <div className={classes.arrowsContainer}>{renderArrows()}</div>
      {renderTabs()}
    </AppBar>
  );
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Header;
